<template lang="pug">
  div.wrap-module-others
    div.others-list.f.fc
      div.list-content.f.fm.flex-between.py8
          v-col(v-if="settings.selected")
            v-row
              v-col
                span(v-if="atLeastOne") 送信方法：
                span(v-else).error-message 送信方法：一つ以上選択してください
            v-row
              v-col
                ValidationProvider(name="Eメール" :rules="{ required: !atLeastOne }" v-slot="{ errors }")
                  v-checkbox(class="py-0 mt-n4 mb-0" v-model="selected" label="Eメール" value="email")
            v-row
              v-col
                ValidationProvider(name="メールアドレス" :rules="{ required: useEmail, email }" v-slot="{ errors }")
                  v-text-field(
                    class="pt-0 mt-n6"
                    v-model="email" 
                    label="メールアドレス" 
                    placeholder="例: example@truffletechnologies.co.jp" 
                    :disabled="!useEmail"
                    :error-messages="errors"
                  )
            v-row
              v-col
                ValidationProvider(name="SMS/+メッセージ" :rules="{ required: !atLeastOne }" v-slot="{ errors }")
                  v-checkbox(class="py-0 mt-n4 mb-0" v-model="selected" label="SMS/+メッセージ" value="sms")
            v-row
              v-col
                ValidationProvider(name="電話番号" :rules="{ required: useSMS, min: 10, regex: /^[0-9\-]{10,}$/ }" v-slot="{ errors }")
                  v-text-field(
                    class="pt-0 mt-n6"
                    v-model="telNo" 
                    label="電話番号" 
                    placeholder="例: 0123456789 (ハイフンなし)" 
                    :disabled="!useSMS"
                    :error-messages="errors"
                  )
          v-col(v-else class="text-center")
            v-progress-circular(:indeterminate="!settings.selected" color="primary")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-others {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .others-list {
    position: relative;
    border-bottom: solid $border_size $border_color;
    .list-content {
      width: $base_width_percent;
      max-width: $base_max_width_px;
      margin: 0 auto;
    }
  }
}

.error-message {
  color: red;
}
</style>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    selected: {
      get: function () {
        return this.settings.selected ? this.settings.selected : []
      },
      set: function (selected) {
        this.$emit('settingsChanged', { ...this.settings, ...{ selected } })
      }
    },
    email: {
      get: function () {
        return this.settings.email
      },
      set: function (email) {
        this.$emit('settingsChanged', { ...this.settings, ...{ email } })
      }
    },
    telNo: {
      get: function () {
        return this.settings.telNo
      },
      set: function (telNo) {
        this.$emit('settingsChanged', { ...this.settings, ...{ telNo } })
      }
    },
    atLeastOne() {
      return this.selected.length > 0
    },
    useEmail() {
      return this.selected.includes('email')
    },
    useSMS() {
      return this.selected.includes('sms')
    }
  },
  methods: {}
}
</script>
