var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap-module-others"},[_c('div',{staticClass:"others-list f fc"},[_c('div',{staticClass:"list-content f fm flex-between py8"},[(_vm.settings.selected)?_c('v-col',[_c('v-row',[_c('v-col',[(_vm.atLeastOne)?_c('span',[_vm._v("送信方法：")]):_c('span',{staticClass:"error-message"},[_vm._v("送信方法：一つ以上選択してください")])])],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Eメール","rules":{ required: !_vm.atLeastOne }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"py-0 mt-n4 mb-0",attrs:{"label":"Eメール","value":"email"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}}],null,false,3479559215)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"メールアドレス","rules":{ required: _vm.useEmail, email: _vm.email }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-0 mt-n6",attrs:{"label":"メールアドレス","placeholder":"例: example@truffletechnologies.co.jp","disabled":!_vm.useEmail,"error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,false,3065180804)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"SMS/+メッセージ","rules":{ required: !_vm.atLeastOne }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"py-0 mt-n4 mb-0",attrs:{"label":"SMS/+メッセージ","value":"sms"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}}],null,false,787481801)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"電話番号","rules":{ required: _vm.useSMS, min: 10, regex: /^[0-9-]{10,}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-0 mt-n6",attrs:{"label":"電話番号","placeholder":"例: 0123456789 (ハイフンなし)","disabled":!_vm.useSMS,"error-messages":errors},model:{value:(_vm.telNo),callback:function ($$v) {_vm.telNo=$$v},expression:"telNo"}})]}}],null,false,4021242654)})],1)],1)],1):_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":!_vm.settings.selected,"color":"primary"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }