import { firebaseFunctions } from '../components/utils/firebase'

export const requestReschedule = firebaseFunctions.httpsCallable('requestReschedule')
export const updateMeetingAttendee = firebaseFunctions.httpsCallable('updateMeetingAttendee')

export const getSettings = firebaseFunctions.httpsCallable('getSettings')
export const updateSettings = firebaseFunctions.httpsCallable('updateSettings')

/**
 * @type {(param: {paymentMethodId: string}) => Promise<{ data: { success: boolean, stripeCustomerId?: string }}}>
 */
export const savePaymentMethodId = firebaseFunctions.httpsCallable('savePaymentMethodId')

/**
 * @type {(param: {couponCode?: string}) => Promise<{ data: { success: boolean } }>}
 */
export const createSubscription = firebaseFunctions.httpsCallable('createSubscription')

/**
 * @type {() => Promise<{ data: { success: boolean } }>}
 */
export const deleteSubscription = firebaseFunctions.httpsCallable('deleteSubscription')

/**
 * @type {() => Promise<{ data: {
 *   coupon: {
 *     id: string
 *     name: string
 *     amount_off: string
 *     percent_off: string
 *     duration: string
 *     duration_in_months: string
 *     metadata: string
 *   }
 * }}>}
 */
export const getCoupon = firebaseFunctions.httpsCallable('getCoupon')
