<template lang="pug">
  Auth(:redirectWithLoginFailed="true")
    Header(:content="headerContent")
    DrawerMenu(v-if="uid"
      :uid="uid" :show="showMenu" @close="toggleMenu")
    div.wrap-meeting.f.fh
      ModuleSettings(:settings="settings" @settingsChanged="onSettingsChanged")
</template>

<style lang="scss" scoped>
.wrap-meeting {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import { getSettings, updateSettings } from '@/services/functions'
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header'
import DrawerMenu from '@/components/shared/DrawerMenu'
import ModuleSettings from '@/components/module/ModuleSettings'

export default {
  components: {
    Auth,
    Header,
    DrawerMenu,
    ModuleSettings
  },
  data() {
    return {
      headerContent: {
        title: '設定',
        left: {
          icon: 'mdi-menu',
          action: this.toggleMenu
        },
        right: {
          label: '保存',
          action: this.save,
          color: '#4285f4'
        }
      },
      showMenu: false,
      settings: {},
      settingsChanged: false
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    onSettingsChanged(settings) {
      this.settings = settings
      this.settingsChanged = true
    },
    /* async */ updateSettingsIfRequired() {
      if (this.settingsChanged) {
        // Don't wait - WORKAROUND for function cold start
        /* await */ updateSettings({ userId: this.uid, settings: this.settings })
        this.settingsChanged = false
        alert('保存しました')
      }
    },
    save() {
      this.updateSettingsIfRequired()
    }
  },
  async mounted() {
    const { data: settings } = await getSettings(this.uid)
    this.settings = settings
  }
  // 離脱時に自動保存するのではなく、ヘッダーで「保存」を押した場合に保存するようにする
  // /* async */ beforeRouteLeave(to, from, next) {
  //   const { selected, email, telNo } = this.settings
  //   let invalid = false
  //   if (selected.length < 1) {
  //     invalid = true
  //   }
  //   if (selected.includes('email') && email === '') {
  //     invalid = true
  //   }
  //   if (selected.includes('sms') && telNo === '') {
  //     invalid = true
  //   }
  //   if (invalid) {
  //     alert('設定エラーがあります。修正してください。')
  //   } else {
  //     this.save()
  //     next()
  //   }
  // }
}
</script>
