<template lang="pug">
  div.wrap-drawer-menu
    transition(name="overlay")
      div(v-if="show" @click="$emit('close')").overlay.cursor-pointer
    transition(name="menu")
      div(v-if="show").wrap-menu
        div(v-if="user").wrap-user.mt32.mb32
          div.f.fc.mb8
            v-avatar(size="120")
              v-img(:src="user.iconURL")
          span.block.text-center {{user.name}}
        div.wrap-list
          div(v-for="item in menu"
            @click="$router.push(item.to)").list.f.fm.px2.pb3.mb6
            v-icon.mr8(:color="item.color") {{item.icon}}
            span.pt2(:style="{color:item.color}") {{item.label}}
        //- div.wrap-others
          div(v-for="item in others").list.f.fm
            span {{item.label}}
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-drawer-menu {
  .overlay {
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  .wrap-menu {
    position: fixed;
    z-index: 2001;
    left: 0;
    top: 0;
    width: 240px;
    height: 100%;
    background: #fff;
    .wrap-list {
      width: 90%;
      margin: 0 auto;
      .list {
        border-bottom: solid $border_size $border_color;
      }
    }
    // .warp-others {
    //   position: absolute;
    //   width: 90%;
    //   bottom: 0;
    //   .list {
    //     border-bottom: solid $border_size $border_color;
    //   }
    // }
  }
}

.overlay-leave-active,
.overlay-enter-active {
  transition: opacity 0.4s $ease_out_expo;
}
.overlay-enter,
.overlay-leave-to {
  opacity: 0;
}
.overlay-enter-to,
.overlay-leave {
  opacity: 1;
}

.menu-leave-active,
.menu-enter-active {
  transition: all 0.4s $ease_out_expo;
}
.menu-enter,
.menu-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
.menu-enter-to,
.menu-leave {
  transform: translateX(0%);
  opacity: 1;
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'

const teamStore = createNamespacedHelpers('team')

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    uid: {
      type: String,
      require: true
    }
  },
  computed: {
    ...teamStore.mapState(['team']),
  },
  data() {
    return {
      user: null,
      menu: []
    }
  },
  watch: {
    team() {
      this.updateMenu()
    }
  },
  async created() {
    this.user = await database.userCollection().findById(this.uid)
    this.updateMenu()
  },
  methods: {
    updateMenu() {
      let menu = [
        { label: '空き予定', to: '/calendar', icon: 'mdi-calendar', color: 'default' },
        { label: 'ミーティング', to: '/home', icon: 'mdi-home', color: 'default' },
        { label: 'アカウント', to: '/account', icon: 'mdi-account', color: 'default' },
        { label: '設定', to: '/settings', icon: 'mdi-cog', color: 'default' },
        { label: 'Meet Awesomeについて', to: '/about', icon: 'mdi-information', color: 'default' },
        { label: 'サインアウト', to: '/sign-out', icon: 'mdi-logout', color: 'red' }
      ]
      if (this.team) {
        menu.splice(2, 0, { label: 'チーム', to: '/team', icon: 'mdi-account-group', color: 'default' })
      } 

      this.menu = menu
    }
  }
}
</script>
